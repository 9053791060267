import Vue from 'vue';

const that = Vue.prototype

export default {

  refreshToken() {
    return that.$request.get('/adm/token')
  },

  getAdmLog(pageNum, pageSize) {
    return that.$request.get('/adm/log', {
      params: {
        pageNum: parseInt(pageNum),
        pageSize: parseInt(pageSize)
      }
    })
  },

  getFileList(pageNum, pageSize, type) {
    return that.$request.get('/file', {
      params: {
        pageNum: pageNum,
        pageSize: pageSize,
        type: type
      }
    })
  },

  getSystemConfig() {
    return that.$request.get('/config')
  },

  editSystemConfig(systemConfig) {
    return that.$request.post('/config', systemConfig)
  },

  uploadFile(file, uploadProgressEvent) {
    return that.$request.post('/file', {
      file: file
    }, {
      timeout: 120000,
      contextType: 'multipart/form-data',
      onUploadProgress: uploadProgressEvent ? uploadProgressEvent : null
    })
  },

  editFileMemo(fileId, descriptionZh, descriptionEn) {
    return that.$request.post('/file/' + fileId, {
      descriptionZh: descriptionZh,
      descriptionEn: descriptionEn
    })
  }

}