import Vue from 'vue'
import App from './App.vue'
import router from './router'
import global from "@/global";
import vuetify from './plugins/vuetify'
import VueParticles from 'vue-particles'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import request from './api/index'
import Swal from "sweetalert2";
import Base64 from 'js-base64'

import './common/css/index.css'

Vue.config.productionTip = false

// sweetAlert
Vue.prototype.$swal = Swal
// 全局统一变量
Vue.prototype.$globalData = global
// 发送请求
Vue.prototype.$request = request

Vue.use(ElementUI);
Vue.use(VueParticles)
Vue.use(Base64)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
